import styled from "@emotion/styled"

import { FlexContainer } from "../../../styled/flexbox.styled"
import { Card } from "@blueprintjs/core"

export const ExcelFunctionsHelpContainer = styled(FlexContainer)({
  width: "100%",
})

export const ExcelFunctionsHelpCard = styled(Card)({
  marginTop: "20px",
  width: "80%",
})
