import { H1, H2, H3, H4, H5, H6, Text } from "@blueprintjs/core"
import { FC } from "react"
import Markdown from "react-markdown"
import { CardContainer, CodeContainer, PreContainer } from "./MarkDownRenderer.styled"

interface MarkdownRendererProps {
  content: string
}

const MarkdownRenderer: FC<MarkdownRendererProps> = ({ content }) => {
  const components = {
    h1: ({ children }: { children: React.ReactNode }) => <H1>{children}</H1>,
    h2: ({ children }: { children: React.ReactNode }) => <H2>{children}</H2>,
    h3: ({ children }: { children: React.ReactNode }) => <H3>{children}</H3>,
    h4: ({ children }: { children: React.ReactNode }) => <H4>{children}</H4>,
    h5: ({ children }: { children: React.ReactNode }) => <H5>{children}</H5>,
    h6: ({ children }: { children: React.ReactNode }) => <H6>{children}</H6>,
    p: ({ children }: { children: React.ReactNode }) => <Text>{children}</Text>,
    code: ({ inline, children }: { inline?: boolean; children: React.ReactNode }) =>
      inline ? <CodeContainer>{children}</CodeContainer> : <PreContainer>{children}</PreContainer>,
    blockquote: ({ children }: { children: React.ReactNode }) => (
      <CardContainer>{children}</CardContainer>
    ),
  }

  return <Markdown components={components}>{content}</Markdown>
}

export default MarkdownRenderer
