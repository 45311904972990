import { Card } from "@blueprintjs/core"
import MarkdownRenderer from "../../components/MarkDownRenderer"
import { addinFunctionsHelper } from "./addinFunctionsHelper"
import { ExcelFunctionsHelpCard, ExcelFunctionsHelpContainer } from "./ExcelFunctionsHelp.styled"

const ExcelFunctionsHelp = () => {
  return (
    <ExcelFunctionsHelpContainer flexDirection="column" gap="20px" alignItems="center">
      <ExcelFunctionsHelpCard>
        <MarkdownRenderer content={addinFunctionsHelper} />
      </ExcelFunctionsHelpCard>
    </ExcelFunctionsHelpContainer>
  )
}

export default ExcelFunctionsHelp
