export const addinFunctionsHelper = `
### Scient Excel Add-in Functions


#### Common Options for All Functions

All functions support the following optional parameters as key-value pairs:

\`\`\`
orient:   Data orientation (v/h)            Default: v
headers:  Include headers (true/false)      Default: false
dates:    Include dates (true/false)        Default: true
from:     Start date (YYYY-MM-DD)          Example: 2023-01-01
to:       End date (YYYY-MM-DD)            Example: 2024-03-20
limit:    Maximum number of rows           Example: 1000
currency: Currency code for conversion     Example: USD
\`\`\`


---


##### Function Reference

Available Functions:
* **SC.DATASET**
  * Purpose: Get dataset data
  * Use for: Bloomberg or Scient identifiers
  * Parameter order: identifier, [option1, value1, option2, value2, ...]
  
* **SC.FACTORS**
  * Purpose: Get factor data
  * Use for: Bloomberg identifiers
  * Parameter order: identifier, [option1, value1, option2, value2, ...]
  
* **SC.FX**
  * Purpose: Get FX rates
  * Use for: Currency pair conversions
  * Parameter order: currencyFrom, [currencyTo], [option1, value1, option2, value2, ...]
  
* **SC.STOCKPRICE**
  * Purpose: Get stock prices
  * Use for: Bloomberg tickers
  * Parameter order: identifier, [option1, value1, option2, value2, ...]

> **Note:** For all functions, provide required parameters first, then specify options as key-value pairs. Each option should be followed by its value.

**Examples with Parameter Order:**
\`\`\`
=SC.DATASET("BZVLMITK Index", "dates", TRUE, "headers", FALSE)
           ^required           ^option  ^value ^option  ^value

=SC.FACTORS("MSFT US Equity", "from", "2023-01-01", "headers", TRUE)
           ^required         ^option ^value      ^option  ^value

=SC.FX("EUR", "JPY", "orient", "h", "headers", TRUE)
      ^req   ^req   ^option  ^val  ^option  ^value

=SC.STOCKPRICE("AAPL US Equity", "from", "2023-01-01", "limit", 100)
               ^required         ^option ^value      ^option ^value
\`\`\`


#### SC.DATASET

Gets dataset data for a Bloomberg or Scient identifier

**Required Parameters:**
* identifier: Bloomberg or Scient identifier

**Example:**
\`\`\`
=SC.DATASET("BZVLMITK Index", "dates", TRUE, "headers", FALSE, "orient", "h")
\`\`\`


#### SC.FACTORS

Gets factor data for a Bloomberg identifier

**Required Parameters:**
* identifier: Bloomberg identifier

**Example:**
\`\`\`
=SC.FACTORS("MSFT US Equity", "from", "2023-01-01", "headers", TRUE)
\`\`\`


#### SC.FX

Gets FX rate data for a currency pair

**Required Parameters:**
* currencyFrom: Base currency code (e.g. EUR)
* currencyTo: Target currency code (optional, default: USD)

**Example:**
\`\`\`
=SC.FX("EUR", "JPY", "orient", "h", "headers", TRUE)
\`\`\`


#### SC.STOCKPRICE

Gets stock price data for a Bloomberg ticker

**Required Parameters:**
* identifier: Bloomberg ticker

**Example:**
\`\`\`
=SC.STOCKPRICE("AAPL US Equity", "from", "2023-01-01", "limit", 100)
\`\`\`
`
