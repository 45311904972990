import { useQueryClient } from "@tanstack/react-query"
import { useCallback, useEffect } from "react"
import { useDispatch } from "react-redux"
import { Outlet, Route, Routes, useLocation } from "react-router-dom"
import authDuck from "../state/modules/auth"
import profileDuck from "../state/modules/profile"
import { fetchFieldList } from "../state/modules/shared"
import { NavAndSideStyled } from "../styled/layout.styled"
import { WebsocketProvider } from "../websocket/WebsocketProvider"
import Nav from "./Nav"
import ProtectedRoute from "./ProtectedRoute"
import ScientGlobalStateProvider from "./ScientGlobalStateProvider"
import { useAuth } from "./hooks"
import { useApi } from "./hooks/useApi"
import AnalyseDetail from "./pages/Analysis/Detail"
import AnalyseList from "./pages/Analysis/List"
import NewAnalyse from "./pages/Analysis/New"
import Config from "./pages/Config/Config"
import Detail from "./pages/DataSets/Detail"
import List from "./pages/DataSets/List"
import Desks from "./pages/Desks"
import Desk from "./pages/Desks/Desk"
import Events from "./pages/Events"
import Home from "./pages/Home"
import { IdeaCacheProvider } from "./pages/Ideas/Contexts/IdeaCacheProvider"
import IdeaCreate from "./pages/Ideas/Create/IdeaCreate"
import Ideas from "./pages/Ideas/Ideas"
import Login from "./pages/Login/Login"
import Noc from "./pages/Noc/Noc"
import NotFound from "./pages/NotFound"
import PasswordResetConfirm from "./pages/PasswordReset/PasswordResetConfirm"
import PasswordResetRequest from "./pages/PasswordReset/PasswordResetRequest"
import PortfolioManagement from "./pages/PortfolioManagement/PortfolioManagement"
import { TradingCacheProvider } from "./pages/PortfolioManagement/Trading/Contexts/TradingCacheProvider"
import StockDetail from "./pages/Stocks/Detail/StockDetail"
import StockStartPage from "./pages/Stocks/StartPage"
import ExcelFunctionsHelp from "./pages/Addin/ExcelFunctionsHelp"

export const SCIENT_ROUTES = {
  ADMIN: "/admin",
  ANALYSES: "/analysis",
  CONFIG: "/config",
  CONSENSUS: "/consensus",
  DATASETS: "/datasets",
  DATASETS_DETAIL: "/datasets/:id/*",
  DESKS: "/desks",
  ENTRIES: "/datasets/:id/:periodicity/entries",
  ENTRIES_CHART: "/datasets/:id/:periodicity/entries/chart",
  ENTRIES_TABLE: "/datasets/:id/:periodicity/entries/table",
  EVENTS: "/events",
  HOME: "/",
  IDEAS: "/ideas",
  LOGIN: "/login",
  PASSWORD_RESET_REQUEST: "/reset-password/request",
  LOGOUT: "/logout",
  MOCKUP: "/mockup",
  NOC: "/noc",
  PORTFOLIO_MANAGEMENT: "/portfolio-management",
  STOCKS: "/stocks",
  STOCKS_DETAIL: "/stocks/:slug",
  SESSION: "/session",
  PASSWORD_RESET_CONFIRM: "/reset-password/confirm/:token",
  ADDIN: "/addin",
}

const ScientRouter = () => {
  const queryClient = useQueryClient()
  const { authenticated, profile } = useAuth()
  const { api2FAVerified } = useApi()
  const location = useLocation()

  /**
   * On location change update Intercom
   */
  useEffect(() => {
    if (process.env.REACT_APP_INTERCOM === "true") {
      // @ts-ignore
      window.Intercom("update")
    }
  }, [location])

  /**
   * Invalidate queries associated to live events
   * when the websocket successfully reconnects
   * after being disconnected
   */
  const onRetrySuccessWs = useCallback(() => {
    queryClient.refetchQueries({
      predicate: query => {
        return (
          (query.queryKey[0] as string).includes("ideas") ||
          (query.queryKey[0] as string).includes("messages")
        )
      },
    })
  }, [queryClient])

  const dispatch = useDispatch()

  /**
   * Legacy fetch using redux
   * @todo Before removal we need to:
   * - Remove user profile prefs userstockconfig from redux, remove all sheets actions from profile
   */
  useEffect(() => {
    if (authenticated) {
      dispatch(profileDuck.actions.fetchProfile())
      if (api2FAVerified) {
        dispatch(authDuck.actions.fetchGroupList())
        dispatch(authDuck.actions.fetchUserList())
        dispatch(fetchFieldList())
      }
    }
  }, [dispatch, authenticated, api2FAVerified])

  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute>
            <WebsocketProvider onRetrySuccess={onRetrySuccessWs}>
              <ScientGlobalStateProvider>
                <IdeaCacheProvider>
                  <TradingCacheProvider>
                    <NavAndSideStyled>
                      {/* TODO: Correctly type when autodux is removed */}
                      {/* @ts-ignore */}
                      <Nav />
                      <Outlet />
                    </NavAndSideStyled>
                  </TradingCacheProvider>
                </IdeaCacheProvider>
              </ScientGlobalStateProvider>
            </WebsocketProvider>
          </ProtectedRoute>
        }
      >
        <Route path={SCIENT_ROUTES.HOME} element={<Home />} />
        {!profile?.groups.includes("Restricted") && (
          <>
            <Route path={SCIENT_ROUTES.ANALYSES} element={<AnalyseList />}>
              <Route path="new" element={<NewAnalyse />} />
              <Route path=":id" element={<AnalyseDetail />} />
            </Route>
            {/* TODO: Correctly type when autodux is removed */}
            {/* @ts-ignore */}
            <Route path={SCIENT_ROUTES.EVENTS} element={<Events />} />
            <Route path={SCIENT_ROUTES.STOCKS} element={<StockStartPage />}>
              <Route path=":stockSlug" element={<StockDetail />} />
            </Route>
            {/* TODO: Correctly type when autodux is removed */}
            {/* @ts-ignore */}
            <Route path={SCIENT_ROUTES.DATASETS} element={<List />} />
            {/* TODO: Correctly type when autodux is removed */}
            {/* @ts-ignore */}
            <Route path={SCIENT_ROUTES.DATASETS_DETAIL} element={<Detail />} />
            <Route path={SCIENT_ROUTES.DESKS} element={<Desks />}>
              <Route path=":deskId" element={<Desk />} />
            </Route>
            <Route path={SCIENT_ROUTES.IDEAS} element={<Ideas />}>
              <Route path="create" element={<IdeaCreate />} />
              <Route path=":ideaId/update" element={<IdeaCreate />} />
              <Route path=":ideaId/revision/create" element={<IdeaCreate />} />
            </Route>
            <Route path={SCIENT_ROUTES.NOC} element={<Noc />} />
          </>
        )}
        <Route path={SCIENT_ROUTES.PORTFOLIO_MANAGEMENT} element={<PortfolioManagement />} />
        <Route path={SCIENT_ROUTES.CONFIG} element={<Config />} />
        <Route path={SCIENT_ROUTES.ADDIN} element={<ExcelFunctionsHelp />} />
      </Route>
      <Route path={SCIENT_ROUTES.LOGIN} element={<Login />} />
      <Route path={SCIENT_ROUTES.PASSWORD_RESET_REQUEST} element={<PasswordResetRequest />} />
      <Route path={SCIENT_ROUTES.PASSWORD_RESET_CONFIRM} element={<PasswordResetConfirm />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default ScientRouter
