import styled from "@emotion/styled"
import { Card, Code, Colors, Pre } from "@blueprintjs/core"
import { SCIENT_COLORS } from "../../styled/scientColors"

export const CodeContainer = styled(Code)({
  backgroundColor: SCIENT_COLORS.darkGray3,
  color: SCIENT_COLORS.white,
  borderRadius: "4px",
  border: `1px solid ${SCIENT_COLORS.darkGray2}`,
  margin: "16px 0",
  padding: "16px",
})

export const PreContainer = styled(Pre)({
  backgroundColor: SCIENT_COLORS.darkGray3,
  color: SCIENT_COLORS.white,
  borderRadius: "4px",
  border: `1px solid ${SCIENT_COLORS.darkGray2}`,
  margin: "16px 0",
  padding: "16px",
})

export const CardContainer = styled(Card)({
  backgroundColor: SCIENT_COLORS.darkGray3,
  color: SCIENT_COLORS.white,
  margin: "16px 0",
  padding: "12px 20px",
  borderLeft: `4px solid ${SCIENT_COLORS.darkGray2}`,
})
